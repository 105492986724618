import { ChipData } from '@/shared/model/ChipData';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { Route } from 'vue-router';
import AddressEditDialog from './address-edit-dialog/address-edit-dialog.vue';
import { namespace } from 'vuex-class';
import address, { Address } from '@/shared/model/address';

const logger = new Logger('addresses');
const addressModule = namespace('address');
const authModule = namespace('auth');
const documentModule = namespace('document');

@Component({
  components: { AddressEditDialog },
})
export default class AddressesView extends Vue {
  @authModule.Getter('EditAddressesAllowed')
  private EditAddressesAllowed!: boolean;
  @authModule.Getter('isOwner')
  private isOwner!: boolean;
  @authModule.Action('updateLeftMenuOpenState')
  private actionUpdateLeftMenuOpenState: any;

  @authModule.Action('updateHeaderNavData')
  private actionUpdateHeaderNavData: any;

  @addressModule.Getter('getAddresses')
  private getAddresses?: any;
  @addressModule.Action('getAddresses')
  private actionGetAddresses?: any;
  @addressModule.Getter('getAddressesSearchParams')
  private getAddressesSearchParams?: any;
  @addressModule.Action('updateAddress')
  private actionUpdateAddress?: any;
  @addressModule.Action('deleteAddress')
  private actionDeleteAddress?: any;

  get addressesItems() {
    return this.getAddresses.items;
  }

  private isSaveBtnLoading = false;

  private itemsPerPageOptions: number[] = [50, 100, 250, 500];

  getAddressesOnOptionsUpdate(searchOptions: any) {
    // (ED-1032) need the `$nextTick` to catch `getAddresses` v-model update to reset page to 1st page when clear the search field
    this.$nextTick(() => {
      this.addresses.searchParams.dataOption = searchOptions;
      this.actionGetAddresses({ searchParams: this.addresses.searchParams, searchTerm: this.searchAddress?.trim() });
    });
  }
  additionalFields: any[] = [];
  get addresses() {
    return { searchParams: this.getAddressesSearchParams, total: this.getAddresses.total };
  }
  get headers() {
    const headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('customer_number'), value: 'konto' },
      { text: this.$t('last_name'), value: 'nachname' },
      { text: this.$t('first_name'), value: 'vorname' },
      { text: this.$t('mark'), value: 'kennzeichen' },
      { text: this.$t('serial_number'), value: 'seriennummer' },
      { text: this.$t('documents'), value: 'documentsCount' },
    ];
    if (this.EditAddressesAllowed || this.isOwner)
      headers.push({
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
      });
    return headers;
  }

  created() {
    this.setSearchFilterAndAddress();
    this.setAddressesNavHeader();
  }

  setAddressesNavHeader() {
    this.actionUpdateHeaderNavData({ translateId: 'addresses', to: '/addresses' });
  }

  //#region (ED-1232) Save `address` search and reset it if navigate to `addresses` via `left-menu`
  private setSearchFilterAndAddress() {
    if (this.isDocumentSearchEmpty()) {
      this.getAddressesSearchParams.filter = '';
    }
    this.searchAddress = this.getAddressesSearchParams.filter;
  }

  private isDocumentSearchEmpty() {
    return this.documentsSearchPayload.chipDataArray.length === 0;
  }
  //#endregion

  addressEditDialog = {
    show: false,
    model: { ...address.parse({}) },
  };

  async addressEditDialogOnClose(address: Address) {
    this.addressEditDialog.show = false;
  }

  async addressEditDialogOnUpdate(address: Address) {
    this.isSaveBtnLoading = true;
    this.actionUpdateAddress(address)
      .then((result: any) => {})
      .catch((err: any) => {})
      .finally(() => {
        this.addressEditDialog.show = false;
        this.isSaveBtnLoading = false;
        this.actionGetAddresses();
      });
  }

  onClickAddressAdd() {
    this.addressEditDialog.model = { ...address.parse({}) };
    this.addressEditDialog.show = true;
  }
  onClickAddressEdit(address: Address) {
    this.addressEditDialog.model = { ...address };
    this.addressEditDialog.show = true;
  }

  async onClickAddressDelete(address: Address) {
    this.$confirm
      .open(`${this.$t('delete_dialog_title')}`, `${this.$t('delete_dialog_text', { 0: address.konto })}`, {
        cancelText: `${this.$t('cancel')}`,
        okText: this.$t('ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteAddress(address.id)
            .then(() => {
              this.actionGetAddresses();
            })
            .catch((err: any) => {
              logger.error(err);
            });
        }
      });
  }

  searchAddress = '';
  isLastRequestWithSearch = false;

  async onInputSearchAddress(event: any, callFromClearIcon: boolean) {
    const searchTerm = event.target.value;
    this.getAddressesSearchParams.filter = searchTerm;
    if (searchTerm && searchTerm.length >= 2) {
      this.addresses.searchParams.dataOption.page = 1; // (ED-1032) Reset the page `because` if we will be on the 10th page with pagesize 50 (results 500-550) and our search will return 99 results then we will be still on the page 10 (results 500-550), so then we should do 1 of the things: 1) reload the page 2) click back to the 2nd page from page 10 3) change itemsPerPage
      await this.actionGetAddresses({ searchParams: this.addresses.searchParams, searchTerm })
        .then((result: any) => {
          this.isLastRequestWithSearch = true;
          logger.log('result :>> ', result);
        })
        .catch((err: any) => {
          logger.error(err);
        });
    } else if (this.isLastRequestWithSearch || callFromClearIcon) {
      // need `isLastRequestWithSearch` to avoid extra requests if user hit Enter many times on empty `Search` input
      this.addresses.searchParams.dataOption.page = 1;
      await this.actionGetAddresses({ searchParams: this.addresses.searchParams })
        .then(() => {
          this.isLastRequestWithSearch = false;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }
  @documentModule.Mutation('resetDocumentsSearchPayload')
  private resetDocumentsSearchPayload!: () => {};
  @documentModule.Getter('documentsSearchPayload')
  private documentsSearchPayload!: any;

  private resetAndGo(organisationId: string, chipData: ChipData) {
    this.actionUpdateLeftMenuOpenState(false);
    this.resetDocumentsSearchPayload();
    (this.documentsSearchPayload.chipDataArray as ChipData[]) = [chipData];
    this.$router.push(`/dashboard/organization/${organisationId}`).catch(() => {});
  }

  kontoClick(item: any) {
    this.resetAndGo(item.organisationId, {
      chipText: `Kundennummer (Adresse): ${item.konto.trim()}`,
      field: 'AdresseKundennummer',
      searchWord: item.konto.trim(),
    });
  }
  kennzeichenClick(item: any) {
    this.resetAndGo(item.organisationId, {
      chipText: `Kennzeichen (Adresse): ${item.kennzeichen.trim()}`,
      field: 'AdresseKennzeichen',
      searchWord: item.kennzeichen.trim(),
    });
  }
  seriennummerClick(item: any) {
    this.resetAndGo(item.organisationId, {
      chipText: `Seriennummer (Adresse): ${item.seriennummer.trim()}`,
      field: 'AdresseSeriennummer',
      searchWord: item.seriennummer.trim(),
    });
  }
  documentsCountClick(item: any) {
    this.resetAndGo(item.organisationId, {
      chipText: `Id (Adresse): ${item.id.trim()}`,
      field: 'AddressId',
      searchWord: item.id.trim(),
    });
  }
}
